import React,{useState} from 'react';

import { FaSave } from 'react-icons/fa';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import {Pad, SubmitButton, CancelButton, StyledTextArea} from './StyledWidgets';


function EditStory(props) {

    

    const [editedContent, setEditedContent] = useState({
        storyroot: props.storyRoot ?? false,
        title: props.title ?? "",
        segment: props.segment
      });

    function handleChange(event) {
        const { name, value } = event.target;
        setEditedContent((prevData) => {
            return {
                ...prevData,
                [name]: value
            };
        });
    }

    async function handleStoryEdit() {

        
    
        const data = {
          segment: editedContent.segment,
          title: editedContent.title,
          userId: props.userSubmitted._id,
          storyId: props.storyId
        }
        await props.editStory(props.indexArray,data);
    
    
      };


return (
    <>
        <StoryContentWrapper
        >
            {props.storyRoot && <StyledTextArea name="title" value={editedContent.title} onChange={handleChange} type="text" />}

            <StyledTextArea fontSize="0.7em" onHeightChange={props.handleHeightChange} name="segment" value={editedContent.segment} onChange={handleChange} type="text" />
        </StoryContentWrapper>
        <Pad/>
        <ButtonsWrapper>
            <SubmitButton onClick={() => { handleStoryEdit(); props.toggleEdit(false); }} >
                <FaSave />
            </SubmitButton>
            <CancelButton onClick={props.cancel} >Cancel</CancelButton>
        </ButtonsWrapper>
    </>
)

}

export default EditStory;




const MyButton = styled.button`
  background-color: var(--color-background);
  border: none;
  font-size: 1.5em;
  border-radius: 0;
  align-self: left;
  padding: 0;
  margin: 0;
`



const StoryContentWrapper = styled.div`
  grid-column: 1 / span 4;  
  grid-row: 1 / span 1;
  background-color: var(--color-background);
  padding-top: 0.5em;

`


const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: end;
  gap: 0.5em;
`


