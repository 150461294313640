import { React, useState, useContext, useEffect } from 'react';
import { useParams, Outlet, useLocation, useNavigate } from 'react-router-dom';

import StoryService from '../services/StoryService';
import { AuthContext } from "../context/AuthContext";
import { Link } from 'react-router-dom';
import StorySample from '../components/StorySample';
import ForkCard from '../components/Archived Components/ForkCard';
import StoryCard from '../components/cards/StoryCard';
import styled from 'styled-components';
import { FaRegHandPointRight } from 'react-icons/fa';
import { MyButton, CustomLink } from '../components/StyledWidgets';





function UserHomePage() {

  const { isAuthenticated, user } = useContext(AuthContext);

  // params must be named as they are used in the route
  // const {username} = useParams();
  const location = useLocation()
  // ;

  const [storyFeed, setStoryFeed] = useState([]);
  // const [following, setFollowing] = useState(false);
  const navigate = useNavigate();


  // create a use effect to fetch a random assortment of stories from the backend
  // the backend should send the stories in order of most recent first.
  // clicking on the story will take the user to the story page.

  useEffect(() => {
    StoryService.getStoryFeed()
      .then(res => {
        setStoryFeed(res.data);
      })
      .catch(err => {
        ;
      })
  }, []);

  function navCharacterBuilder() {
    navigate('/characterbuilder')
  }

  function navCharactersPage() {
    navigate(`/user/${user.username}/characters`)
  }



  return (
    <Wrapper>

      <Pad padding='1em' />
      <FlexWrapper>
      <ColumnWrapper>
      <Heading>Welcome, {user.username}!</Heading>
      <br></br>
      <SubHeading>Start a new story, or fork an existing story.</SubHeading>
      <Pad padding='1em' />
      
        <CenteredDiv>
          <CustomLink grow height='4em' to='/new-story'>Start Writing! <FaRegHandPointRight /> </CustomLink>
        </CenteredDiv>

        </ColumnWrapper>
        <Pad  />
        
        <ColumnWrapper>
          <CustomLink variant='secondary' to={`/user/${user.username}/stories`}>Your Stories</CustomLink>
          <Pad padding= '0.3em' />
          <CustomLink variant='secondary' to={`/user/${user.username}/forks`}>Your Story Forks</CustomLink>
          <Pad padding= '0.3em' />
          <CustomLink variant='secondary' to={`/user/${user.username}/characters`}>Your Characters</CustomLink>
          <Pad padding= '0.3em' />
          <CustomLink variant='secondary' to='/characterbuilder'>Character Builder</CustomLink>




        </ColumnWrapper>
      </FlexWrapper>
      <Pad padding='1em' />


      {/* <MyButton onClick={navCharacterBuilder}>Character Builder</MyButton> */}
      {/* <MyButton onClick={navCharactersPage}>Characters</MyButton> */}



      <h4>Your Fork Feed:</h4>
      <Pad padding='0.5em' />

      <CardsContainer>
        {storyFeed.map((story, index) => {
          return (
            <StoryPad>

              <StoryCard {...story} />

            </StoryPad>
          )
        }
        )}
      </CardsContainer>

      

      {/* <main>
        <Outlet />
      </main> */}

    </Wrapper>
  );
}

export default UserHomePage;

const Pad = styled.div`

padding:  ${(props) => props.padding || '1em'};
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    ${'' /* margin: 0 10% ; */}
`;

const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    `

const ColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    `

    const FlexWrapper = styled.div`
    display: flex;
    flex-direction: row;
    @media (max-width: 600px) {
      flex-direction: column;
    }
    justify-content: space-between;
    `


const Heading = styled.h4`
`

const SubHeading = styled.p`
`

const StoryPad = styled.div`
padding : 0.2rem;
display: flex;
flex-direction: column;
justify-content: space-between;
height: 100%;

@media (min-width: 600px) {
  width: 100%;
}

@media (min-width: 900px) {
  width: 50%;
}

@media (min-width: 1200px) {
  width: 33.33%;

}
`

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

`;

const CardsContainer = styled.div`
display: flex;
flex-wrap: wrap;
font-size: 16px;
align-items: stretch;
`;


const CTALink = styled(Link)`
  ${'' /* display: inline-block; */}
  background-color: var(--color-secondary); 
  color: var(--color-background);
  font-weight: bold;
  border-radius: 0.5em;
  padding: 0.7em 1em;
  // width: 50%;
  font-size: 1.5em;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: ease-in-out 200ms;
  &:hover {
        transform: scale(1.2);
        ${'' /* color: var(--color-background) */}
    }
&:active {
transform: scale(1.2);
${'' /* color: var(--color-secondary); */}
}

`;




