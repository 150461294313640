import React from 'react'; 
import {Outlet} from 'react-router-dom';

import styled from 'styled-components';

function MainLayout() {
// let the user know what the screen width is
// alert(`The screen width is ${window.innerWidth}px`);

return (
    <>

        <StyledMain>
            <Outlet />
        </StyledMain>
    

    </>

);
}

export default MainLayout;

const StyledMain = styled.main`
  padding-left: 1rem;
  padding-right: 1rem;

  @media (min-width: 1080px) { 
    padding-left: 0;
    padding-right: 0;
    width: 1080px;
    margin: 0 auto;
  }
`;