import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import StoryContent from '../StoryContent';
import { toSlug } from '../../utils/regex';




const CardWrapper = styled(Link)`
  border: 1px solid;
  border-color: var(--color-primary);
  &:hover {
    border-color: var(--color-secondary);
    border-thickness: 0.4em;
  }

  border-radius: 0.2em;
  padding: 1.5em;
  height: 20em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;


const Content = styled.p`
  ${'' /* font-size: 1.5em; */}
  margin-bottom: 0.2em;
  display: -webkit-box;
  -webkit-line-clamp: 15;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  @media (min-width: 600px) {
    -webkit-line-clamp: 10;

}

@media (min-width: 900px) {
  -webkit-line-clamp: 4;

}


`;



const AuthorH4 = styled.h5`
padding-left: 1em;

`

const SecondaryText = styled.p`
  margin-bottom: 0.2em;
  padding-left: 1em;
`;

const Number = styled.span`
  color: var(--color-secondary);
  margin-right: 0.1em;
  cursor: pointer;
`;


const Pad = styled.div`
  ${'' /* padding: 0.5em; */}
`

const Title = styled.h5`
  ${'' /* font-size: 1.4em; */}
  font-weight: bold;
  color: var(--color-tertiary);
  ${'' /* // height: 3.6em; */}

`;

const RowWrapper = styled.div`
display: flex;
flex-flow: row wrap;
${'' /* font-size: 0.8em; */}
`
const FlexItem = styled.div`

`

const StyledH4 = styled.h5`
  padding-left: 1em;
`;

const StyledLink = styled(Link)`
color: var(--color-accent);
`



// props = {content, date, likes, forkCount}
function StoryCard(props) {
  console.log(props)

  const storyRoot = props.storyRoot;

  const title = props.parentRootTitle
  const rootId = props.parentRootShortId 
  const forkId = props.shortId || props._id
  const kebabTitle = toSlug(rootId,title)


  const link = !storyRoot ? `/story/${kebabTitle}/fork/${forkId}` : `/story/${kebabTitle}`;
  const dateSubmitted = new Date(props.createdAt).toLocaleDateString("en-GB", {
    year: "numeric",
    month: "short",
    day: "numeric"
  });

  return (
    <CardWrapper to={link}>

      <FlexItem>
        {!storyRoot && <StyledH4>A Fork of </StyledH4>}
      
        <Title>
          {title}
        </Title>

        <AuthorH4> by <StyledLink to={`/user/${props.userSubmitted.username}`}>{props.userSubmitted.username}</StyledLink></AuthorH4>
      </FlexItem>
      <Pad />
      {/* <Content>{props.content.substring(0,150) +"..." }</Content> */}

      <FlexItem>

        <Content>
          <StoryContent fontSize='inherit' segment={props.segment} />
        </Content>
        {/* <Content>{props.content}</Content> */}
      </FlexItem>

      <Pad />
      <RowWrapper>

        <SecondaryText>Submitted on {dateSubmitted} • </SecondaryText>
        <SecondaryText><Number>{props.likes}</Number> {props.likes === 1 ? "like" : "likes"} • </SecondaryText>

        <SecondaryText><Number>{props.forks}</Number> {props.forks === 1 ? "Story Fork" : "Story Forks"}</SecondaryText>
      </RowWrapper>




    </CardWrapper>
  );
};




export default StoryCard;

