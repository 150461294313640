

import {React, useState, useEffect, useContext} from 'react';
import {useParams,  useLocation} from 'react-router-dom';
import StoryService from '../services/StoryService';
import styled from 'styled-components';
import {AuthContext} from '../context/AuthContext';

import UserCard from '../components/cards/UserCard';
import ForkCard from '../components/Archived Components/ForkCard';
import StoryCard from '../components/cards/StoryCard';
import { CustomLink } from '../components/StyledWidgets';

// this page will be used for both the logged in users profile page
// and the visiting user profile page. 
// the logged in user will be able to edit various things

function UserProfilePage() {


const {username} = useParams();
;






return (
  <PageWrapper>
    <Pad />
    <FlexWrapper>

    <UserCard  username={username} />

    <Pad />

    



        <CustomLink variant='secondary' to={`/user/${username}/stories`}>View {username}'s Stories</CustomLink>
    <Pad />

    <CustomLink variant='secondary' to={`/user/${username}/forks`}>View {username}'s Story Forks</CustomLink>
    <Pad />
    <CustomLink variant='secondary' to={`/user/${username}/characters`}>View {username}'s Characters</CustomLink>
    </FlexWrapper>
       

  </PageWrapper>
        
);
}

export default UserProfilePage;

const PageWrapper = styled.div`
${'' /* padding: 2em; */}
`;



const Pad = styled.div`
    padding: 1em;
`;

const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    gap: 1em;
    `

const FlexWrapper = styled.div`
    display: flex;
    flex: 1;
    // width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    @media (max-width: 600px) {
        flex-direction: column;
    }
    justify-content: space-between;
    `

const StoryPad = styled.div`
padding : 0.2rem;
display: flex;
flex-direction: column;
justify-content: space-between;
height: 100%;

@media (min-width: 600px) {
  width: 100%;
}

@media (min-width: 900px) {
  width: 50%;
}

@media (min-width: 1200px) {
  width: 33.33%;

}
`

const Heading = styled.h1`
    ${'' /* font-size: 4rem; */}
    font-weight: bold;
    margin-bottom: 1rem;
    margin-left: 1rem;

`;

const CardsContainer = styled.div`
display: flex;
flex-wrap: wrap;
font-size: 16px;
align-items: stretch;
`;





      {/* {location.pathname !== `/user/${username}/authored` && 
        <p>
        <Link to={`/user/${username}/authored`}>Check out {userDetails.username}'s authored stories</Link>

        </p>
        }
       
        {location.pathname !== `/user/${username}/liked` && 
        <p>
        <Link to={`/user/${username}/liked`}>Check out {userDetails.username}'s liked stories</Link>

        </p> */}