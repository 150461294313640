import React, {useState, useEffect, useContext} from 'react';
import { gtag } from 'ga-gtag';

import GridWrapper from '../components/GridWrapper';
import {Link, useNavigate} from 'react-router-dom';
import WritingBuddySample from '../components/WritingBuddySample';
import StoryLoader from '../components/StoryLoader';

import image from '../assets/StoryFork.png';

// import a cursor pointer from react icons
import {FaRegHandPointRight} from 'react-icons/fa';

import styled from 'styled-components';
import TextTransition, { presets } from 'react-text-transition';

// import AuthContext
import {AuthContext} from '../context/AuthContext';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import StoryService from '../services/StoryService.js';



const LandingPage = () => {




gtag('event', 'conversion', {'send_to': 'AW-11262443896/md9QCILcyOEYEPiCrfop'});


    const navigate = useNavigate();
    const {isAuthenticated} = useContext(AuthContext);
    
    // state for DBstats
    const [dbStats, setDbStats] = useState({});

    const [index, setIndex] = useState(0);

    useEffect(() => {
        // if isAuth is true, redirect to the dashboard
        if (isAuthenticated) {
            navigate("/home");
        }
    }, [isAuthenticated]);

    useEffect(() => {
        const intervalId = setInterval(
          () => setIndex((index) => index + 1),
          3000, // every 3 seconds
        );
        return () => clearTimeout(intervalId);
      }, []);

      // fetch dbStats
      useEffect(() => {

        async function getStats() {
        await StoryService.getDBStats()
        .then(
          (response) => setDbStats(response.data))
          .catch(
            (err) => console.log(err))
          }
          getStats();
        
      }, []);




  return (
    <>
      <LightWrapper >
      <ConstrainedLightDiv>
        <HeadlineDiv>
          <Mainheadlinediv>
            <HeadlineFirstDiv>
              <HeadlineFirstH1>Write</HeadlineFirstH1>
              <HeadlineFirstH1>&nbsp;Stories</HeadlineFirstH1>
            </HeadlineFirstDiv>
            <HeadlineSecondDiv>
              <HeadlineSecondH1>With&nbsp;</HeadlineSecondH1>
              <TextTransition style={{ textAlign: 'right' }} direction="up" springConfig={presets.default}>
                <HeadlineSecondH1><GreySpan>{TEXTS[index % TEXTS.length]}</GreySpan></HeadlineSecondH1>
              </TextTransition>

            </HeadlineSecondDiv>
          </Mainheadlinediv>
          <Subheadlinediv>
            <Subheadline>
              StoryFork is a social creative writing platform that lets you write, share, and contribute to fiction with other authors.
            </Subheadline>
            <HeadlineCTAdiv>
              <CTALink variant="primary" to='/auth/register'>Join StoryFork <Pointer /></CTALink>
              <CTALink variant="secondary" to='/story/explore'>Explore Stories</CTALink>

            </HeadlineCTAdiv>
          </Subheadlinediv>
        </HeadlineDiv>
        </ConstrainedLightDiv>
      </LightWrapper>

        <Pad/>
        <GradientDiv />
        


              <DarkWrapper>
              <ConstrainedDarkDiv>
                <SubHeadDiv>
                    <h2>Create <GreySpan>Branching</GreySpan> Narratives.</h2>
                    <Pad/>
                    <h6>We use a 'forking' system for contributing to stories. Imagine each fork as a potential path for the story, each of which can further branch out into its own narrative threads. Simply swipe the text to experience the different branches of the story.</h6>
                    <Pad/>
                    <DarkCTALink variant="secondary" to='/auth/register'>Sign Up</DarkCTALink>

                </SubHeadDiv>

                <WidgetDiv>
                <WidgetText>Try It Out! <GreySpan><DownPointer/></GreySpan></WidgetText>
                <Pad/>

                <StoryWrapper>

                <StoryLoader  /> 
                </StoryWrapper>

                </WidgetDiv>
                </ConstrainedDarkDiv>
              </DarkWrapper>

<FlippedGradientDiv/>

      <LightWrapper>
      <ConstrainedLightDiv>

        <WidgetDiv>
          <WidgetText>Give It A Go! <GreySpan><DownPointer /></GreySpan></WidgetText>
          <Pad />

          <WritingBuddySample />

        </WidgetDiv>
        <SubHeadDiv>
          <h2>Write With <GreySpan>AI.</GreySpan></h2>
          <Pad />
          <h6>
            Wave goodbye to writer's block with our life-saving Writing Buddy feature. Write a (complete) sentence and our AI will whip up the next one.
          </h6>
          <Pad />
          <CTALink variant="primary" to='/auth/register'>Sign Up</CTALink>

        </SubHeadDiv>
</ConstrainedLightDiv>
      </LightWrapper>

      <GradientDiv />

      <DarkWrapper>
      <ConstrainedDarkDiv>
        <div style={{display: "flex", flexDirection: "column"}}>
        <h2>Website Stats</h2>
        <Pad/>
        <h6>StoryFork is a just a baby, and we're still growing. Here are some stats about our website:</h6>
        <Pad/>

        <p>Story Count: {dbStats.storyCount}</p>
        <p>Fork Count: {dbStats.forkCount}</p>
        <p>User Count: {dbStats.userCount}</p>
        <p>Active User Count: {dbStats.activeUserCount} (the number of active users in a week)</p>
        <Pad/>
        <DarkCTALink variant="secondary" to='/auth/register'>Help Us Grow!</DarkCTALink>
        </div>
</ConstrainedDarkDiv>
      </DarkWrapper>
      <FlippedGradientDiv/>

        

              </>
            
  
  );
};

export default LandingPage;


const TEXTS = ['Anyone','Friends', 'Family', 'Classmates', 'AI', 'Strangers', 'Yourself', 'The Hivemind']

 

const HeadlineDiv = styled.div`
display: flex;
flex-direction: column;
gap: 3em;

`;

const Mainheadlinediv = styled.div`
padding: 0 3em 0 0 ;
max-width: 600px;

`


const HeadlineFirstDiv = styled.div`
display: flex;
flex-direction: column;

@media (min-width: 1080px) {
    flex-direction: row;

}
justify-content: flex-end;
text-align: right;
`;

const HeadlineSecondDiv = styled.div`
display: flex;

@media (min-width: 1080px) {
    flex-direction: row;
    justify-content: flex-start;

}
flex-direction: column;
justify-content: flex-start;
`;

const Subheadline = styled.h5`
flex-grow: 2;
`

const Subheadlinediv = styled.div`
display: flex;
flex-direction: column;
gap: 4em;
align-items: center;
@media (min-width: 900px) {
    gap: 1em;
    flex-direction: row;
}
`

const HeadlineCTAdiv = styled.div`
flex-grow: 1;
display: flex;
flex-direction: column;
gap: 1em;
width: 100%;
justify-content: flex-start;


`


const HeadlineFirstH1 = styled.h1`
color: var(--color-secondary);
font-size: 2.3em;
`;

const HeadlineSecondH1 = styled.h1`
color: var(--color-secondary);
font-size: 2.3em;

${'' /* display: inline-flex; */}
  ${'' /* font-size: 4.8rem; */}
  ${'' /* text-align: right; */}
`;


const GreySpan = styled.span`
color: var(--color-accent);
font-size: 2.3rem;
`


const QuatSpan = styled.span`
color: var(--color-tertiary);
`

const CTALink = styled(Link)`
display: inline-block;
background-color: var(--color-tertiary); 
color: var(--color-background);
font-weight: bold;
border-radius: 0.5rem;
width: 100%;
font-size: 1.5em;
padding: 0.5rem 0.7rem;
text-decoration: none;
text-align: center;
white-space: nowrap;
cursor: pointer;
transition: ease-in-out 50ms;

&:hover {
    transform: scale(1.05);
}

${'' /* &:active {
    transform: scale(1.2);
} */}

@media (min-width: 900px) {
width: 100%;
font-size: 0.5em;
border-radius: 0.2rem;

    }

${props => props.variant === 'primary' && `
    background-color: var(--color-accent); 
    color: var(--color-background);
`}

${props => props.variant === 'secondary' && `
    background-color: var(--color-background); 
    color: var(--color-secondary);
    border: 1px solid var(--color-accent);
`}
`;

const DarkCTALink = styled(CTALink)`
    background-color: #0c0d0c;
`;

const Pointer = styled(FaRegHandPointRight)`
    ${'' /* font-size: 4rem; */}
`

const DownPointer = styled(FaRegHandPointRight)`
   transform: rotate(90deg);
`

const Pad = styled.div`
padding: 1rem;
background-color: rgba(0, 0, 0, 0);
`

const GradientDiv = styled(Pad)`
    background: linear-gradient(0deg, #0c0d0c, var(--color-background));
  width: 100%; 
  height: 100%;
`;

const FlippedGradientDiv = styled(GradientDiv)`
background: linear-gradient(180deg, #0c0d0c, var(--color-background));

`;


const ConstrainedLightDiv = styled.div`
    display: flex;
flex-direction: column-reverse;
padding: 4em 1.5em;

gap: 4em;
width: 100%;

  @media (min-width: 1080px) { 
    flex-direction: row;

    ${'' /* padding-left: 0;
    padding-right: 0;
    width: 1080px; */}
    margin: 0 auto;
    justify-content: space-between;
    ${'' /* padding: 4em 3em ; */}
gap: 2em;

flex-direction: row;
  }
`;

const ConstrainedDarkDiv = styled(ConstrainedLightDiv)`
    flex-direction: column;
    @media (min-width: 1080px) { 
    flex-direction: row;

    ${'' /* padding-left: 0;
    padding-right: 0;
    width: 1080px; */}
    margin: 0 auto;
    ${'' /* padding: 4em 3em ; */}
gap: 2em;

flex-direction: row;
  }
`;

const LightWrapper = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;

  @media (min-width: 1080px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const DarkWrapper = styled(LightWrapper)`

  background-color: #0c0d0c;
  ${'' /* flex-direction: column;
  @media (min-width: 1080px) {
    padding: 4em 3em;
    gap: 2em;
    
    flex-direction: row;
    
    }

    a, button, textarea {
    color: #c9c9c9;
    background-color: #0c0d0c;
  }


  button {
    background-color: #0c0d0c; // Dark color for button
  } */}

`;

const SubHeadDiv = styled.div`
display: flex;
flex-direction: column;
width: 100%;
// justify-content: center;
padding: 2em 0;
gap: 1em;
@media (min-width: 1080px) {
  width:40%;
  gap: 0em;

  }
`

const WidgetDiv = styled.div`
display: flex;
flex-direction: column;

overflow: hidden; 
text-overflow: ellipsis; 
width: 100%;
// height: 150vh;
text-align: center;
// justify-content: center;

@media (min-width: 1080px) {
  width: 40%;
  font-size: 50%;
  }



`

const WidgetText = styled.h2`
font-size: 1.7em;
text-align: left;
@media (min-width: 900px) {
  font-size: 3em;
  text-align: center;

  }

`

const PaddingTop = styled.div`
padding-top: 2em;
`

const StoryWrapper = styled.div`
border: 1px solid var(--color-secondary);
border-radius: 0.5em;

}
`



