import React, { useState, useRef, useEffect } from "react";
import styled from 'styled-components';
import StoryService from "../services/StoryService";
import CharacterService from "../services/CharacterService";
import {toSlug} from '../utils/regex';

import OpenAiService from "../services/OpenAiService";
import { useNavigate, redirect } from "react-router-dom";
import { StoryContext } from "../context/StoryContext";
import StyledSwitch from "./StyledSwitch";
import { Mention, MentionsInput } from 'react-mentions';
import MentionsInputStyle from '../styles/MentionsInputStyle';
import {MyButton, StyledButton} from '../components/StyledWidgets';
import renderMention from "./RenderMention";

// import a react send icon
import { FaPaperPlane } from "react-icons/fa";

// import a save icon from react icons
import { IoSave } from "react-icons/io5";
import Modal from 'react-modal';
Modal.setAppElement('#root');




function CreateStory(props) {
  // console.log(`CreateStory props: ${JSON.stringify(props)}`)

  // set up navigate
  const navigate = useNavigate();

  const [writingBuddy, setWritingBuddy] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [characters, setCharacters] = useState([]);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
const customStyles = { 
  overlay: {
    maxHeight: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 9998
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '90%',
    maxHeight: '90%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'var(--color-background)',
    zIndex: 9999   
  }
};

const [modalIsOpen, setModalIsOpen] = useState(false);

function openModal() {
  setModalIsOpen(true);
}

function closeModal() {
  setModalIsOpen(false);
}

useEffect(() => {
  if (modalIsOpen) {
      document.body.classList.add('modal-open');
  } else {
      document.body.classList.remove('modal-open');
  }
}, [modalIsOpen]);

  

  async function handleSendPrompt() {
    console.log(prompt); 
    setIsRequesting(true);
    // setContent(content + " ");
    let accumulatedContent = content;

   // make a request to OpenAI.sendPrompt(prompt) and stream the response to the content state
    for await (const newcontent of OpenAiService.sendPrompt(prompt)) {
      accumulatedContent += newcontent;

      // Update the state with the accumulated content
      setContent(accumulatedContent);
    }


    setIsRequesting(false);
  };


  const textAreaRef = useRef(null);




  const charCount = content.length;
  const charLimit = 1250;
  const overLimit = charCount > charLimit;

  // follow the cursor as text is added to textarea
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
    }
  }, [content]);


  // format punctuation after request is finished
  useEffect(() => {
    if (!isRequesting) {
      if (content.length > 0) {
        formatContent(content)
      }
      // textAreaRef.current.focus();
    }
  }, [isRequesting])

  // load all the characters from the database
  useEffect(() => {
    CharacterService.getCharacters().then((res) => {
      // convert all the characters to an id and display name
      const conv_characters = res.data.map((character) => {
        return {
          id: character._id,
          display: character.name
        }
      })

      setCharacters(conv_characters);
    }).catch((err) => {
      console.error(err);
    });
  }, []);

function handleTitleChange(event) {
  const { name, value } = event.target;

  setTitle(value);
}



  // handler to update the title and content state
  async function handleContentChange(event) {
    if (isRequesting) {
      return;
    }
    const { name, value } = event.target;

    setContent(value);


    if (value.length < content.length) {
      return;
    }

    const isTerminal = isLastCharTerminalPunctuation(value);

    if (writingBuddy && isTerminal && !isRequesting) {
      generateNextSentence(value);
    }

  }



  function handlePromptChange(event) {
    const { name, value } = event.target;

    setPrompt(value);
  }






  function formatContent(content) {
    // Trim the content first
    content = content.trim();

    // Regular expression for checking terminal punctuation
    const terminalPunctuation = isLastCharTerminalPunctuation(content)

    if (!terminalPunctuation) {
      setContent(content + ". ");
    } else {
      setContent(content + " ");
    }

  }

  function isLastCharTerminalPunctuation(str) {
    // 
    const lastChar = str.slice(-1);
    return /[.!?]$/.test(lastChar);
  }


  // generate next sentence of the story
  async function generateNextSentence(str) {
    
    setIsRequesting(true);
    // await setContent(str + " ");
    let accumulatedContent = str + " ";

    for await (const newcontent of OpenAiService.generateNextSentence(str)) {
      accumulatedContent += newcontent;
      setContent(accumulatedContent);
    }
    setIsRequesting(false);

  }



  async function submitNewStory() {
    if (overLimit) {
      alert('Your story is too long. Try again.')

      return;
    }
    if (content.length < 1) {
      alert('Your story doesn\'t have any content. Try again.')
      return;
    }
    if (title.length < 1) {
      alert('Your story doesn\'t have a title. Try again.')
      return;
    }

    // check to make sure only letters, numbers, and spaces
    const regex = /^[a-zA-Z0-9 ]*$/;
    

    if (!regex.test(title)) {
      alert('Your title can only contain letters, numbers, and spaces. Try again.')
      return;
    }


    // alert(JSON.stringify(props))
    StoryService.postStory({storyroot:true,title,content}).then((res) => {
      const newID = res.data.newStory.shortId || res.data.newStory._id;
      const kebabTitle = toSlug(newID, title);

      navigate(`/story/${kebabTitle}`, { replace: true })

    }).catch((err) => {
      ;
    });



  };



  return (
    
<>

      <Padding padding={'2em'}>
        <FlexWrapper>
        {/* <Modal
  isOpen={modalIsOpen}
  onRequestClose={closeModal}
  shouldCloseOnOverlayClick={true}
  shouldCloseOnEsc={true}
  shouldFocusAfterRender={true}
  style={customStyles}
>
  <FlexColumn>
          <PromptInput
            style={MentionsInputStyle}
            input="textarea"
            value={prompt}
            name="prompt"
            onChange={handlePromptChange}
            placeholder="Describe what happens in your story here... include characters with '@'"

          >
            <Mention
              // markup={renderMention}
              displayTransform={(id, display) => `<a href='/characters/${id}'>${display}</a>`}
              trigger="@"
              data={characters}
            />
          </PromptInput>
          <Pad/>
      <StyledButton onClick={() =>{handleSendPrompt(); closeModal();}}>Create</StyledButton>
      </FlexColumn>
      </Modal> */}


          <TitleTextWrapper>
            <TitleText
              placeholder="Title"
              name="title"
              input="textarea"

              value={title}
              onChange={handleTitleChange} />
            <Pad padding={'0.5em'} />

          </TitleTextWrapper>

          <TextAreaWrapper>

            <ContentInput
              style={MentionsInputStyle}
              input="textarea"
              value={content}
              rows={10}
              name="content"
              onChange={handleContentChange}
              placeholder="This is where you write your story. Mention characters with '@'. Turn Writing Buddy on to get help writing your story, or leave it off to write solo. When you are done writing, hit submit to publish your story."

            >
              <Mention
                trigger="@"
                data={characters}
              />
            </ContentInput>
            <p>{(overLimit) ? "over the limit buddy, reel it in a little" : `${charCount} / ${charLimit}`}</p>
          </TextAreaWrapper>
          <Pad padding={'0.2em'} />
          <RowWrapper>
          <SwitchWrapper>
            <StyledSwitch checked={writingBuddy} onToggle={setWritingBuddy} />
            <Pad />
            <p>Writing Buddy {writingBuddy? 'On' : 'Off'}</p>
          </SwitchWrapper>
          <Pad />
          {/* <StyledButton onClick={openModal}>Story Magic</StyledButton>
          <Pad /> */}

          <SubmitButton
            onClick={submitNewStory}
            disabled={overLimit}
          >
          Submit
            {/* <FaPaperPlane /> */}

          </SubmitButton>
          </RowWrapper>

        </FlexWrapper>
      </ Padding >

      <Pad padding={'0.5em'} />




    </>
  )
}

const PromptInput = styled(MentionsInput)`
overflow-y: scroll;
`
const ContentInput = styled(MentionsInput)`
@media (max-width: 768px) {
min-height: 25em;
}
@media (min-width: 768px) {
font-size: 0.5em;
}
`

const Padding = styled.div`
  padding: ${(props) => props.padding};
`

const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  `

const FlexWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

`

const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`



const TextAreaWrapper = styled.div`
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  width: 100%;
  ${'' /* margin: 50px; */}
`




const StyledTextArea = styled.textarea.attrs({
  rows: 10,
})`
  width: 100%;
  overflow: auto;
  flex-grow: 1;
  padding: 0.5em;
  rows: 10;
  resize: none;
  vertical-align: center;
  horizontal-align: center;
  font-size: 1em;
  outline: none;
  border-radius: 7px;
  box-sizing: border-box;
  border-color: var(--color-primary);
  color: var(--color-primary);
  background-color: var(--color-background);
  ::placeholder {
    color: var(--color-secondary);
    opacity: 0.8;
};
  &:hover {
    ::placeholder {
      opacity: 1;
    };
  };
&:focus {
  outline: none;
  ::placeholder {
    transition: opacity 0.5s 0.5s ease!important;
    opacity: 0.35;
    color: var(--color-secondary);
};
};
`




const TitleText = styled(StyledTextArea).attrs({
  rows: 1,
})`
font-size: 1em;
text-align: center;

`





const TitleTextWrapper = styled.div`

`




// // make this a solid colour element, not using .button
// const StyledButton = styled.button`
//   color: var(--color-background);
//   background-color: var(--color-primary);
//   font-size: 1em;
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;

//   border: 1px solid var(--color-primary);
//   border-radius: 0.1em;
//   ${'' /* border-width: 0.2rem; */}
//   box-sizing: border-box;
//   height: 2em;
//   overflow: hidden;
//   white-space: nowrap;
//   resize: none;
//   padding: 0.5em 2em;
//   &:hover {
//     background-color: var(--color-secondary);
//   };
//   cursor: pointer;
// `

const SubmitButton = styled(StyledButton)`
`


const Pad = styled.div`
  padding: 1em;
  padding: ${(props) => props.padding};

  `
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  `

export default CreateStory;
