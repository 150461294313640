import React,{StrictMode} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import AuthProvider from "./context/AuthContext"
import StoryProvider from "./context/StoryContext"


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<StrictMode>
    <AuthProvider><StoryProvider><App /></StoryProvider></AuthProvider>
    </StrictMode>
    );

