import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

function StoryContent(props) {

// check screen width and assign to variable
const screenWidth = window.innerWidth;
console.log(screenWidth);

// set font size based on screen width
let fontSize = '12px';
if (screenWidth < 768) {
  fontSize = '9px';
} else if (screenWidth < 1024) {
    fontSize = '12px';
} else if (screenWidth < 1440) {
    fontSize = '14px';
} else {
    fontSize = '14px';
}



//     const defaultFontSize = '0.7rem';
//   const fontSize = props.fontSize || '0.7rem';



  function transformMentions(text) {
    const pattern = /@\[([^\]]+)\]\(([^)]+)\)/g;
    const jsxElements = [];
    let lastIndex = 0;

    text.replace(pattern, (match, displayName, id, index) => {
        // Add the preceding text (if any) as a <span> element
        if (index > lastIndex) {
            jsxElements.push(<StorySegment fontSize={fontSize} key={lastIndex}>{text.substring(lastIndex, index)}</StorySegment>);
        }

        // Add the mention as a <Link> element
        jsxElements.push(<MentionLink fontSize={fontSize} to={`/characters/${id}`} key={id} >{displayName}</MentionLink>);
        
        lastIndex = index + match.length;
    });

    // Add any remaining text after the last mention as a <span> element
    if (lastIndex < text.length) {
        jsxElements.push(<StorySegment fontSize={fontSize} key={lastIndex + "-end"}>{text.substring(lastIndex)}</StorySegment>);
    }

    return jsxElements;
}



    const transformedSegment = transformMentions(props.segment);

    return (
 <StoryContentWrapper  >
            {transformedSegment.map(element => element)}
    </StoryContentWrapper>
    );
}

export default StoryContent;

const StoryContentWrapper = styled.div`
    font-size: ${(props) => props.fontSize};
    line-height: 0.7rem;
    padding-bottom: 0.1em;
    width: 100%;
    text-align: left;
    white-space: pre-wrap;
    &:focus {
      color: var(--color-secondary);
  };
  cursor: pointer;

`;

const StorySegment = styled.span`
font-size: ${(props) => props.fontSize};

    width: 100%;
    color: var(--color-primary);

    user-select: auto;
 &:active {
    color: var(--color-secondary);
  }


    visibility: ${(props) => props.visibility};
`;

const MentionLink = styled(Link)`
font-size: ${(props) => props.fontSize};

&:hover {
    color: var(--color-accent);
};
`



