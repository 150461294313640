import React, { useState, useEffect } from "react";
import styled from "styled-components";
import StoryService from "../services/StoryService";
import ForkCard from '../components/Archived Components/ForkCard';
import StoryCard from '../components/cards/StoryCard';


// This is the component that will fetch and render the root stories
function StoryExplorer() {
  // Use a state variable to store the stories
  const [stories, setStories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {storyLoader()}, []);

  const storyLoader = async () => {
      await setIsLoading(true);
      await StoryService.getRecentStoriesAndForks()
      .then(async  res => {
          console.log(`response recieved`);
          return res.data;
      }
      ).then(async (data) => {
          await setStories(data);
          await setIsLoading(false);
      })
      .catch(err => {
          console.log(err);
      }
      )
  }


  const RootStoriesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  align-items: stretch;

`;

const Heading = styled.h1`
  font-size: 1.5rem;
`


const StoryPad = styled.div`
padding : 0.5em 0.2em ;
display: flex;
flex-direction: column;
justify-content: space-between;
height: 100%;

@media (min-width: 600px) {
  width: 100%;
}

@media (min-width: 900px) {
  width: 50%;
}

@media (min-width: 1200px) {
  width: 33.33%;

}
`
const Pad = styled.div`
padding : ${(props) => props.padding || '0.5em'};
`


const Wrapper = styled.div`
padding : 1rem;
`

  return (

    <Wrapper>
    <Pad padding="0.5em"/>

    <Heading>Recent  Submissions:</Heading>
        <Pad padding="0.5em"/>

    {isLoading ? <p>Loading...</p> : <>

    <RootStoriesContainer>
      {stories.map((story) => (
        // Use the StoryCard component to display each story
        <StoryPad>
        <StoryCard {...story} />
     

        </StoryPad>

      ))}
    </RootStoriesContainer>
    </>}

</Wrapper>
  );
}

export default StoryExplorer;

