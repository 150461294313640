export default {
    control: {

      fontWeight: 'normal',
      padding: '1em 0.1em',

    },
  
    '&multiLine': {
      control: {
        fontFamily: 'var(--font-body)',
        // minHeight: 63,
      },
      highlighter: {
        padding: '1em 0.1em',
        border: '1px solid transparent',
      },
      input: {
        padding: '0.5em 0.5em',
        // border: '1px solid silver',
      },
    },
  
    // '&singleLine': {
    //   display: 'inline-block',
    //   width: 180,
  
    //   highlighter: {
    //     padding: 1,
    //     border: '2px inset transparent',
    //   },
    //   input: {
    //     padding: 1,
    //     border: '2px inset',
    //   },
    // },
  
    suggestions: {
      list: {
        backgroundColor: 'var(--color-background)',
        border: '1px solid var(--color-primary)',
        fontSize: '1em',
      },
      item: {
        padding: '5px 15px',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        '&focused': {
          backgroundColor: 'var(--color-background)',
        },
      },
    },
  }